import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {UserSearchingInterface, UserSearchInterface} from '../interfaces/user.interface';

const USER_SEARCH_ENDPOINT = '/v1/account/search'; // GET

@Injectable({
  providedIn: 'root'
})
export class UserSearchService {

  public appEndpointPath: string;

  constructor(private readonly httpClient: HttpClient) {
  }

  public searchResults: Subject<UserSearchInterface> = new Subject();

  public get searchResults$(): Observable<UserSearchInterface> {
    return this.searchResults.asObservable();
  }

  public setSearchResults(results: UserSearchInterface): void {
    this.searchResults.next(results);
  }

  public searchUser(searchTerm: string, page: number, size: number, registrationDate?: string): Observable<Object> {
    let queryString = '';
    const defaultSize = 15;

    if (searchTerm) {
      queryString += searchTerm ? `searchTerm=${searchTerm}` : '';
    }

    if (registrationDate) {
      queryString += registrationDate ? `&registrationDate=${registrationDate}` : '';
    }

    if (page) {
      queryString += page ? `&page=${page}` : '';
    }

    if (size) {
      queryString += queryString ? `&size=${size}` : defaultSize;
    }

    return this.httpClient.get(`${environment.apiUrl}` + this.appEndpointPath + USER_SEARCH_ENDPOINT + '?' + queryString);
  }


  public getUserDataByPage(searchValues: UserSearchingInterface, currentPage: number, itemsPerPage: number): void {
    currentPage = currentPage === 0 ? currentPage : currentPage - 1;
    this.searchUser(
      searchValues.searchTerm,
      currentPage,
      itemsPerPage,
      searchValues.registrationDate).subscribe(
      (participantSearchModel: UserSearchInterface) => {
        this.setSearchResults(participantSearchModel);
      });
  }

}
