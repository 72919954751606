import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {lastValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private content: any;

  constructor(private readonly httpClient: HttpClient) {
  }

  public async getContent(key: string): Promise<any> {
    await this.loadContent();
    return this.content[key];
  }

  public async loadContent(antiCacheQueryString: string = ''): Promise<void> {
    const response = this.httpClient.get(`./assets/content/shared-content.json${antiCacheQueryString}`);
    this.content = await lastValueFrom(response);
  }
}
